.profile {
 
    &__landing {
        height: 20rem;
        background-color: #002147;
        color: $color-white;

        display: flex;
        justify-content: space-around;
        align-items: center;

        @include respond(tab-land) {
            padding: 0 2rem;
            justify-content: space-between;
        }

        @include respond(phone-large) {
            flex-direction: column;
            gap: 4rem;
            height: auto;
            padding: 4rem 0;
        }

        &__user {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3rem;

            &__avatar {

                img {
                    width: 10rem;
                    height: 10rem;
                    border-radius: 50%;
                }
            }

            &__details {
                p {
                    margin-bottom: 1rem;
                }

                &__startup {

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    padding: 5px;

                    border: 2px solid $color-white;
                    font-size: 1.3rem;
                    border-radius: 2rem;
                }
            }
        }

        &__about {

            display: flex;

            

            &__item {

                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 1rem;
                padding: 1rem 2rem;

                &--1 {
                    border-right: 1px solid $color-white;
                }

                &__label {

                }

                &__value {
                    font-weight: bold;
                    font-size: 1.5rem;
                }
            }
        }

    }

    &__main {

        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
        background-color: $color-grey-light-1;
        padding: 2rem;

        @include respond(tab-land) {
            flex-direction: column-reverse;
        }

        &__edit {
            width: 50%;
            background-color: $color-white;
            border: 1px solid $color-grey-light-2;
            padding: 2rem;

            @include respond(tab-land) {
                width: 70%;
            }

            @include respond(phone-large) {
                width: 80%;
            }

            @include respond(phone-medium) {
                width: 90%;
            }

            @include respond(phone-small) {
                width: 96%;
            }


            &__item {
                margin-bottom: 2rem;

                &__label {

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1rem;
                    margin-bottom: 1rem;

                    p {
                        font-weight: bold;
                    }

                    &__icon {
                        color: $color-primary-dark;
                        cursor: pointer;
                    }

                    span {
                        color: $color-primary-dark;
                        cursor: pointer;
                    }
                }

                &__value {

                    img {
                        width: 3rem;
                        height: 3rem;
                        border-radius: 50%;
                    }
                }
            }

            &__btn {
                background-color: $color-primary;
                margin: 2rem 0;
                width: 30rem;
            }

        }

        &__loaded {
            
           
            
            color: $color-white;

            &__first {
                padding: 2rem;
                background-color: $color-tertiary-dark;
                margin-bottom: 3rem;
            }

            &__second {
                background-color: $color-secondary-dark;
                padding: 2rem;
            }

            p {
                font-weight: bold;
                margin-bottom: 1rem;
            }

            &__wrap {
               
                display: flex;
                width: 30rem;
                height: 1rem;

                

                &__filled {
                    
                    flex: 8;
                    background-color: white;

                    border-top-left-radius: 1rem;
                    border-bottom-left-radius: 1rem;

                    
                }

                &__unfilled {
                    border-top-right-radius: 1rem;
                    border-bottom-right-radius: 1rem;

                    border: 1px solid $color-white;
                    
                    flex: 2;
                    background-color: transparent;
                }
            }

        }
    }
}