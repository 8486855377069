.terms {

    display: flex;
    flex-direction: column;

    h3 {
        font-size: 2.5rem;
        color: $color-white;

        background-color: #4a5d23;

        padding: 2rem;

        @include respond(phone-large) {
            font-size: 2rem;
        }


    }

    &__container {
        flex: 1;
        padding: 2rem;
        color: black;

        h4 {
            margin: 2rem 0;
        }

        p {

        }

        ul {

            li {

            }
        }

        h5 {
            margin: 1.5rem 0;
        }

    }

    &__btnsection {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        margin-bottom: 2rem;
    }

    &__btn {
        width: 25rem;
        background-color: $color-primary;

        @include respond(phone-large) {
            width: 90%;
        }
    }
}