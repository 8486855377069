.editdd {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 3rem;


    h3 {
        font-size: 2.5rem;
    }

    &__input {

        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        @include respond(phone-large) {
            width: 100%;
        }

        label {
            font-weight: bold;
            font-size: 1.6rem;
        }

        input, select {
            padding: 1rem 1.5rem;
            width: 400px;
            font-size: 1.5rem;

            @include respond(phone-large) {
                width: 92%;
            }
        }
    }

    &__btn {
        background-color: $color-primary;
    }
}