.footer {

    display: flex;
    flex-direction: column;
    gap: 2rem;

    justify-content: center;
    align-items: center;

    padding: 4rem 0;

    background-color: #002147;
    color: $color-white;

    &__img {
        padding: .5rem;
        background-color: $color-white;

        img {

            width: 20rem;
            height: auto;

        }

    }

    &__list {
        ul {

            display: flex;
            gap: 3rem;

            @include respond(tab-land) {
                flex-direction: column;
                gap: 1rem;

                align-items: center;
            }

            li {
                list-style: none;
                padding: 1.5rem;
                font-weight: bold;
            }
        }
    }

    &__madeby {
        text-align: center;
    }

}