.landing {

    &__video {

        &__wrapper {
            position: relative;
            overflow: hidden;


        }

        &__container {

            height: 48rem;
            width: 100vw;

            position: relative;



            --mask:
                radial-gradient(223.61px at 50% calc(100% - 300px), #000 99%, #0000 101%) calc(50% - 200px) 0/400px 100%,
                radial-gradient(223.61px at 50% calc(100% + 200px), #0000 99%, #000 101%) 50% calc(100% - 100px)/400px 100% repeat-x;
            -webkit-mask: var(--mask);
            mask: var(--mask);

            &::after {}

            &::before {}

        }

        &__vid {
            width: 100vw;
            height: auto;
            margin: auto;
            display: block;

            @include respond(phone-large) {
                height: 100%;
                object-fit: cover;
            }
        }

        &__text {
            background-image: linear-gradient(to right, rgba($color-black, 0.5), rgba($color-black, 0.6));
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: $color-white;

            @include respond(phone-large) {
                padding-top: 12rem;
                justify-content: flex-start;
            }

            @include respond(phone-medium) {
                padding-top: 10rem;
            }


            &__main {

                font-size: 5rem;
                animation: moveInLeft 1s ease-in-out;

                @include respond(tab-land) {
                    font-size: 3.6rem;
                    text-align: center;
                }

                @include respond(phone-large) {
                    font-size: 2.5rem;
                }

                span {
                    color: $color-tertiary-dark;
                    font-weight: bold;

                }
            }

            &__sub {

                font-size: 3.5rem;
                margin: 2rem 0;
                animation: moveInRight 1s ease-in-out;

                @include respond(tab-land) {
                    font-size: 2.8rem;
                    text-align: center;
                }

                @include respond(phone-large) {
                    font-size: 2rem;
                   
                }

            }
        }

        &__btnsection {}

        &__btn {
            animation: moveInBottom 1s ease-in-out;
            background-color: $color-primary;
        }
    }

    &__intro {

        display: flex;

        margin: 0 10rem;
        margin-bottom: 4rem;

        @include respond(tab-land) {
            margin: 0 6rem;
            margin-bottom: 4rem;
        }

        @include respond(phone-large) {
            margin: 0 3rem;
            margin-bottom: 3rem;
            margin-top: -5rem;
        }

        @include respond(phone-medium) {
            margin: 0 2rem;
            margin-bottom: 3rem;
            margin-top: -5rem;
        }

        @include respond(phone-small) {
            margin: 0 1rem;
            margin-bottom: 3rem;
            margin-top: -5rem;
        }
        

        &__text {
            flex: 2;

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;


        }

        &__img {
            flex: 1;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 10rem;
                height: 10rem;
            }
        }
    }

    &__main {

        background-image: linear-gradient(to right, rgba($color-black, 0.5), rgba($color-black, 0.6)), url(../img/landing.jpeg);
        background-size: cover;
        background-position: center;
        color: white;
        padding: 8rem 4rem;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 8rem;

        &__welcome {
            font-size: 5rem;
            font-weight: bold;
            margin-bottom: 3rem;
            text-align: center;

            @include respond(tab-land) {
                font-size: 4.5rem;
            }
    
            @include respond(phone-large) {
                font-size: 3rem;
            }
    
            @include respond(phone-medium) {
               font-size: 2.5rem;
            }
    
            @include respond(phone-small) {
                
            }
        }

        &__name {
            font-size: 5rem;
            font-weight: bold;
            margin-bottom: 3rem;
            text-align: center;

            @include respond(tab-land) {
                font-size: 4.5rem;
            }
    
            @include respond(phone-large) {
                font-size: 3rem;
            }
    
            @include respond(phone-medium) {
               font-size: 2.5rem;
            }
    
            @include respond(phone-small) {
                
            }

            span {
                color: $color-tertiary-dark;
            }

        }

        &__slogan {
            font-size: 3rem;
            font-weight: bold;
            margin-bottom: 3rem;
            text-align: center;

            @include respond(tab-land) {
                font-size: 2.5rem;
            }
    
            @include respond(phone-large) {
                font-size: 2rem;
            }
    
            @include respond(phone-medium) {
               font-size: 1.7rem;
            }
    
            @include respond(phone-small) {
                
            }

            &__sub {

                font-size: 3rem;
                font-weight: bold;
                margin-bottom: 3rem;
                color: $color-tertiary-light;
                text-align: center;

                @include respond(tab-land) {
                    font-size: 2.5rem;
                }
        
                @include respond(phone-large) {
                    font-size: 2rem;
                }
        
                @include respond(phone-medium) {
                   font-size: 1.7rem;
                }
        
                @include respond(phone-small) {
                    
                }

            }
        }

        &__paragraph {
            width: 50%;
            text-align: center;
            margin-bottom: 3rem;
            font-size: 1.55rem;

            span {
                color: red;
                font-weight: bold;
            }

            @include respond(tab-land) {
                width: 60%;
            }

            @include respond(phone-large) {
                width: 70%;
            }

            @include respond(phone-medium) {
                width: 75%;
            }

            @include respond(phone-small) {
                width: 80%;
            }
        }

        &__btnsection {

            @include respond(phone-large) {
                width: 70%;
            }
        }

        &__btn {
            background-color: $color-primary;
            border: 2px solid $color-primary;

            @include respond(phone-large) {
                width: 95%;
            }
        }
    }

    &__whyjoin {

        margin-bottom: 6rem;

        h3 {

            font-size: 5rem;
            text-align: center;
            margin-bottom: 4rem;

        }

        &__container {
            display: flex;
            gap: 8rem;
            padding: 0 2rem;


            @include respond(tab-land) {
                flex-direction: column;
                gap: 10rem;
                padding: 0 8rem;
            }

            @include respond(phone-large) {
                gap: 7rem;
                padding: 0 3rem;
            }

            @include respond(phone-medium) {
                gap: 5rem;
                padding: 0 1.5rem;
            }
        }

        &__item {

            flex: 1
        }
    }

    &__domains {

        margin-bottom: 4rem;

        padding: 6rem 0;

        background-image: linear-gradient(to right, #43c6ac, #191654);
        color: $color-white;

        h3 {

            font-size: 5rem;
            text-align: center;
            margin-bottom: 4rem;

        }

        &__container {
            display: flex;
            gap: 3rem;
            padding: 0 2rem;

            @include respond(tab-land) {
                flex-direction: column;
                align-items: center;
            }
        }

        &__card {
            flex: 1;
            color: $color-white;
            box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;

            @include respond(tab-land) {
                width: 70%;
            }

            @include respond(phone-large) {
                width: 80%;
            }

            @include respond(phone-medium) {
                width: 90%;
            }

            @include respond(phone-small) {
                
            }


            &__background {
                height: 70%;
                height: 20rem;

                background-size: cover;
                color: white;



                &--1 {
                    background-image: url(../img/digital.webp);
                }

                &--2 {
                    background-image: url(../img/health.jpg);
                }

                &--3 {
                    background-image: url(../img/manufacturing.jpg);
                }

                &--4 {
                    background-image: url(../img/energy.png);
                }

                &--5 {
                    background-image: url(../img/media.jpg);
                }
            }

            &__title {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 2rem;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 1.2rem;

                background-color: rgba($color-black, 0.6)
                
            }

            &__btnsection {}


        }

        &__btn {
            width: 100%;
            background-color: $color-primary;
            border-radius: 0;
        }
    }

    &__timeline {

        margin-bottom: 4rem;

        padding: 6rem 0;

        background-image: linear-gradient(to right, #43c6ac, #191654);


        h3 {

            font-size: 5rem;
            text-align: center;
            margin-bottom: 4rem;
            color: white;

        }

        &__box {

            display: flex;
            gap: 3rem;
            padding: 0 2rem;

            @include respond(tab-land) {
                flex-direction: column;
                align-items: center;
            }

        }

        &__item {

            flex: 1;
            padding: 1rem;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background-color: rgba($color-white, 0.8);

            @include respond(tab-land) {
                width: 60%;
            }

            @include respond(phone-large) {
                width: 70%;
            }

            @include respond(phone-medium) {
                width: 80%;
            }

            @include respond(phone-small) {
                width: 85%;
            }

            &__head {

                display: flex;
                justify-content: center;
                align-items: center;

                gap: 2rem;

                border-bottom: 2px double $color-grey-dark;

                @include respond(tab-land) {
                    justify-content: flex-start;
                    width: 100%;
                    
                }

                &__icon {

                    width: 5rem;
                    height: 5rem;

                    &__box {}
                }

                &__title {

                    &__name {
                        font-weight: bold;
                    }

                    &__date {
                        font-weight: bold;
                        color: $color-secondary-dark;
                    }
                }

            }

            &__text {
                padding-top: 1rem;

            }

        }
    }

    &__organizer {

        margin-bottom: 8rem;

        h3 {
            font-size: 5rem;
            text-align: center;
            margin-bottom: 4rem;
        }

        &__container {

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4rem;
            padding: 0 5rem;

            @include respond(tab-land) {
                flex-direction: column;
            }
        }

        &__imgsection {
            flex: 1;

            img {
                width: 80%;
                height: auto;

                @include respond(tab-land) {
                    width: 40rem;
                    height: auto;
                }
            }
        }

        &__bio {
            flex: 1.5;

            &::first-letter {
                font-size: 4rem;
                color: $color-tertiary-dark;
            }
        }

        &__btnsection {
            margin-top: 4rem;

            &__btn {
                background-color: $color-primary;
            }
        }
    }

}