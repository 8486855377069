.settings {



    &__container {
        padding: 2rem 5rem;
        display: flex;
        gap: 5rem;

        @include respond(tab-land) {
            flex-direction: column;
            padding: 2rem;
        }

        @include respond(phone-large) {
            padding: 2rem 1rem;
        }

        p {}

    }

    &__label {
        flex: 1.5;
        
        @include respond(tab-land) {
            display: none;
            width: 0;
        }

        &__icon {
            width: 1.5rem;
            height: 1.5rem;
            color: $color-primary-light;
        }

        ul {
            padding: 1rem;
            list-style: none;

            li {
                width: 100%;
                border: 1px solid $color-grey-dark-3;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;

                padding: 1rem 0;
                cursor: pointer;

                span {}
            }
        }

        &__phone {

            display: none;
            width: 0;

            @include respond(tab-land) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 2rem;
                width: 100%;
                padding: 2rem;
            }

            &__main {
                width: 80%;
                padding: 2rem;

                display: flex;
                justify-content: space-between;
                border: 2px solid $color-primary;

                span {
                    flex: 1;
                    text-align: center;
                    font-weight: bold;
                    font-size: 1.7rem;
                }

                @include respond(phone-medium) { 
                    width: 90%;
                }
            }

            &__dd {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &__selected {
                    color: $color-primary;
                }

                p {
                    padding: 10px;
                    font-size: 16px;

                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:not(:last-child) {
                        border-bottom: 1px solid #ccc;
                    }
                }
            }

            p {
                font-weight: bold;
                font-size: 1.7rem;
            }

        }
    }

    &__main {
        flex: 4;
        padding: 2rem;

        h3 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }

        &__container {
            width: 85%;

            p {
                font-weight: bold;
                margin-bottom: 2rem;
            }
        }

        &__btnsection {
            width: 100%;
            padding: 1rem;
            display: flex;
            justify-content: flex-end;

        }

        &__btn {
            background-color: $color-primary;
        }
    }

    &__input {
        padding: 1rem 1.5rem;
        font-size: 1.5rem;
        width: 40rem;


        &__iconbox {
            padding: 1rem;

        }

        &__icon {
            width: 2rem;
            height: 2rem;
        }

        &__box {

            display: flex;
            // background-color: $color-grey-light-1;
            border-radius: 2rem;
            margin-bottom: 2rem;

        }

        &__wrap {

            display: flex;
            flex-direction: column;
            gap: 1rem;

            label {
                font-weight: bold;
            }
        }

    }

    &__contact {

        &__item {

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 2rem;

            margin-bottom: 2rem;

            &__icon {
                width: 2rem;
                height: 2rem;
            }

            &__details {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                h4 {}

                p {}
            }
        }
    }

}