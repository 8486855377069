.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;

    padding: 2rem 0;

    &__logo {
        cursor: pointer;

        img {
            width: 20rem;
            height: auto;

            @include respond(phone-large) {
                width: 15rem;
            }

            @include respond(phone-medium) {
                width: 13rem;
            }

            @include respond(phone-medium) {
                width: 10rem;
            }
        }

    }

    &__btnsection {
        display: flex;
        gap: 2rem;

        @include respond(phone-small) {
            gap: 1rem;
        }
    }

    &__btn {

        background-color: transparent;
        color: $color-black;
        border: 1px solid $color-primary;
        padding: 1rem 1.5rem;
        
        &--register {
            
        }

        &--login {

        }
    }
}