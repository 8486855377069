.signup {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 4rem 0;

    background-color: rgba(lightblue, 0.3);


    h3 {
        font-size: 4rem;
        margin-bottom: 2rem;
        text-align: center;

        @include respond (phone-medium) {
            font-size: 3rem;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    &__form {

        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 2rem;

        @include respond(phone-large) {
            width: 92%;

            justify-content: center;
            align-items: center;
        }

        @include respond(phone-medium) {
          width: 94%;  
        }

        @include respond(phone-small) {
            width: 96%;
        }
    }

}