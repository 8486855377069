.domaindd {
    height: 100%;

    color: $color-black;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    &__header {
        width: 100%;
        font-size: 2rem;
        padding: 2rem 1rem;
        color: $color-white;
        // background-color: $color-primary;

        @include respond(phone-large) {
            font-size: 1.75rem;
            
        }
    }

    &__container {
        flex: 1;

        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }

    &__intro {
        padding: 1rem;
        font-weight: bold;
        font-size: 1.6rem;
        margin: 1rem 0;

        @include respond(phone-large) {
            font-size: 1.4rem;
        }
    }

    p {
        padding: 1rem;
    }

    ul {


        display: flex;
        flex-direction: column;
        padding: 1rem 3rem;

        @include respond(phone-large) {
            font-size: 1.35rem;    
        }

        li {}
    }

    &__btnsection {
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }

    &__btn {
        background-color: $color-primary;
    }
}