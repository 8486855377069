.chatroom {
    height: 100vh;
    width: 40rem;
    animation: slideFromRight .4s ease-in;

    position: fixed;
    top: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    background-color: $color-white;
    color: black;
    z-index: 299;

    @include respond(tab-land) {
        width: 60%;
    }

    @include respond(phone-large) {
        width: 85%;
    }

    h3 {
        font-size: 2rem;
        padding: 2rem;
    }

    h4 {
        font-size: 1.7rem;
        padding: 2rem;
    }

    &__close {

        position: absolute;
        top: 2rem;
        right: 3.5rem;

        width: 2.5rem;
        height: 2.5rem;
        color: $color-secondary-dark;

        cursor: pointer;
    }

    &__container {
        padding: 2rem;

        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__message {

        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        &__avatar {
            padding: 5px;
            border-radius: 50%;
            border: 2px solid $color-primary;

            img {
                width: 3rem;
                height: 3rem;

                border-radius: 50%;
            }
        }

        &__content {
            border: 1px solid $color-grey-dark-2;
            padding: 1rem;
            &__head {

                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;

                &__icon {
                    width: 2rem;
                    height: 2rem;
                }

                span {
                    font-weight: bold;
                }

                p {
                    color: $color-tertiary-dark;
                }
            }

            &__text {
                padding: 1rem;
               
            }

        }
    }
}