.home {
    position: relative;

    &__header {
        height: 7rem;

        padding: 0 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond(phone-large) {
            padding: 0 5px;
        }

        @include respond(phone-large) {
            padding: 0 1rem;
        }

        &__logo {
            cursor: pointer;

            img {
                width: 20rem;
                height: auto;

                @include respond(phone-large) {
                    width: 15rem;
                }

                @include respond(phone-medium) {
                    width: 13rem;
                }

                @include respond(phone-medium) {
                    width: 10rem;
                }
            }
        }

        &__user {

            display: flex;
            gap: 3rem;
            justify-content: center;
            align-items: center;

            @include respond(phone-large) {
                gap: 1.5rem;
            }

            p {
                font-weight: bold;

                @include respond(phone-large) {
                    display: none;
                    width: 0;
                }
            }

            &__help {
                width: 3rem;
                height: 3rem;
                cursor: pointer;
                color: $color-primary-light;
            }

            &__messages {
                width: 3rem;
                height: 3rem;
                cursor: pointer;
                color: $color-primary-dark;
            }

            &__avatar {

                padding: 5px;
                border: 2px solid $color-grey-light-2;
                border-radius: 50%;
                cursor: pointer;

                img {

                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: 50%;

                }
            }
        }
    }

    &__landing {
        height: 30rem;
        background-image: url(../img/homelanding.png);
        background-size: cover;
        background-position: center;
        margin-bottom: 4rem;
    }

    &__current {

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5rem;
        gap: 8rem;

        @include respond(tab-land) {
            flex-direction: column;
            gap: 4rem;
        }


        &__notes {

            h3 {
                font-size: 3rem;
                color: $color-secondary-dark;
                padding: 2rem 0;
                border-bottom: 3px double $color-secondary-dark;
                margin-bottom: 2rem;
            }

            h4 {
                font-size: 2rem;
                color: $color-black;
                padding: 2rem 0;
            }

            p {
                padding: 2rem 0;
            }
        }

        &__card {

            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            box-shadow: rgba($color-primary, 0.4) 5px 5px, rgba($color-primary, 0.3) 10px 10px, rgba($color-primary, 0.2) 15px 15px, rgba($color-primary, 0.1) 20px 20px, rgba($color-primary, 0.05) 25px 25px;
            // box-shadow: white 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(193, 27, 116) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(52, 19, 128) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(204, 37, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;

            &__img {
                width: 90%;

                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 30rem;
                    height: 25rem;

                    @include respond(phone-large) {
                        width: 95%;
                    }
                }
            }

            &__title {
                font-weight: bold;
                font-size: 1.8rem;
            }

            &__btnsection {
                width: 100%;
            }

            &__btn {
                width: 100%;
                background-color: $color-primary;
            }
        }
    }

    &__timeline {
        margin-bottom: 4rem;

        padding: 4rem 0;

        background-image: linear-gradient(to right, #43c6ac, #191654);

        h2 {
            font-size: 4rem;
            margin: 4rem 0;
            text-align: center;
            color: $color-white;

            @include respond(phone-large) {
                font-size: 3rem;
            }

            @include respond(phone-small) {
                font-size: 2.3rem;
            }
        }

        &__container {
            padding: 1rem 6rem;

            @include respond(tab-land) {
                padding: 1rem 5rem;
            }

            @include respond(phone-large) {
                padding: 1rem 3rem;
            }

            @include respond(phone-medium) {
                padding: 1rem 2.5rem;
            }

            @include respond(tab-land) {
                padding: 1rem 1.5rem;
            }
        }

        &__item {

            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            gap: 4rem;
            padding: 0 4rem;
            // margin-bottom: 2rem;

            @include respond(tab-land) {
                padding: 0 3rem;
            }

            @include respond(phone-large) {
                padding: 0 2rem;
                gap: 3rem;
            }

            @include respond(phone-medium) {
                padding: 0 1rem;
                gap: 2rem;
            }


            &__label {
                position: relative;

                width: 1rem;

                background-color: $color-white;

                display: flex;
                justify-content: center;
                align-items: center;

                &__circle {

                    width: 2rem;
                    height: 2rem;
                    background-color: $color-primary-dark;
                    position: absolute;

                    top: 50%;
                    left: 50%;

                    transform: translate(-50%, -50%);
                }

            }

            &__content {
                padding: 3rem 2rem;
                margin-bottom: 3rem;
                border: 2px solid $color-primary;
                border-radius: 3rem;
                background-color: rgba($color-white, 0.8);

                flex: 1;

               

                h3 {
                    color: $color-secondary-dark;
                    margin-bottom: 1.5rem;
                }

                &__date {
                    margin-bottom: 1.5rem;
                    font-weight: bold;
                }

                &__text {
                    margin-bottom: 1rem;

                    span {
                        font-weight: bold;
                        color: blue;
                        cursor: pointer;
                    }
                }

            }
        }
    }

    &__editions {
        margin-bottom: 8rem;

        h3 {
            font-size: 4rem;
            text-align: center;

            @include respond(phone-large) {
                font-size: 3rem;
            }

            @include respond(phone-small) {
                font-size: 2.3rem;
            }
        }

        &__container {
            padding: 4rem 0;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4rem;

            @include respond(tab-land) {
                flex-direction: column;
                gap: 8rem;
            }

        }

        &__item {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

            &__img {

                img {
                    width: 30rem;
                    height: auto;

                    @include respond(phone-medium) {
                        width: 27rem;
                    }

                    @include respond(phone-small) {
                        width: 24rem;
                    }
                }
            }

            p {
                font-weight: bold;
                padding: 1rem 0;
            }

            &__btnsection {
                width: 100%;
            }

            &__btn {
                width: 100%;
                background-color: $color-primary;
                border-radius: 0;
            }
        }
    }


}