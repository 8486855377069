.registerdd {
    
    h3 {
        padding: 1rem;
        font-size: 2.5rem;
        margin-bottom: 2rem;
        text-align: center;
    }

    &__container {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;

        p {
            font-weight: bold;
            font-size: 1.8rem;
            margin-bottom: 1.7rem;
            color: blue;
            cursor: pointer;
            padding: 0 3rem;
            text-align: center;
        }

        h4 {
            font-size: 1.5rem;
            margin-bottom: 2rem;
        }
    }

    &__upload {
        margin-bottom: 3rem;

        &__preview {

            &__imgbox {
                padding: 2rem;
                border: 2px solid $color-primary-dark;
                margin-bottom: 1rem; 
                cursor: pointer;

                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 10rem;
                    height: 10rem;
                }
            }

            p {
                font-size: 1.4rem;
                font-weight: normal;
                color: black;
            }
        }
    }


    &__btnsection {

        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__btn {
        background-color: $color-primary;
        width: 30rem;
    }

}