.menu {

    padding:1rem 2rem;
    animation: drop .2s ease-in;

    position: absolute;
    top: 7rem;
    right: 5px;

    background-color: white;

    display: flex;
    flex-direction: column;
    z-index: 99;
    // gap: 1rem;

    

    &__item {

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;

        padding: 1rem;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid $color-grey-dark-2;
        }

        img {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
        }

        &__icon {
            width: 2.5rem;
            height: 2.5rem;
        }

        &__details {
            display: flex;
            flex-direction: column;
            gap: 6px;
            h4 {

            }

            p {

            }
        }
    }
}