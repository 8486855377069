.prizes {

    margin-bottom: 8rem;

    h3 {

        font-size: 5rem;
        text-align: center;
        margin-bottom: 4rem;

    }

    &__container {

        display: flex;
        gap: 3rem;
        padding: 0 3rem;

        @include respond(tab-land) {
            flex-direction: column;
            padding: 0 1rem;
        }

        @include respond(phone-large) {
            width: 100%;
        }
    }

    &__label {

        flex: 1;

        @include respond(tab-land) {
            display: flex;
            justify-content: space-between;
        }

        @include respond(phone-large) {
            width: 100%;
            flex-direction: column;
            align-items: center;
        }
        &__item {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 1rem;
            margin-bottom: 2rem;
            cursor: pointer;

            @include respond(tab-land) {
                display: flex;
            }

            @include respond(phone-large) {
                width: 70%;
            }

            @include respond(phone-medium) {
                width: 80%;
            }

            @include respond(phone-small) {
                width: 95%;
            }

            &__selected {

                border: 2px solid $color-primary;
                border-radius: 3rem;
            }

            &__icon {

                width: 6rem;
                height: 6rem;
                color: $color-black;

                &__box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    gap: 1rem;

                    

                    p {
                        font-size: 1.5rem;
                        font-weight: bold;
                        color: $color-black;
                    }
                }

                &__text {
                    
                    font-size: 1.8rem;
                    width: 30%;
                }
            }
        }

    }

    &__main {

        flex: 2;

        display: flex;
        
        justify-content: center;
        align-items: center;
        

       

        &__box {
            width: 80%;
            height: 80%;
            background-image: linear-gradient(to right, #3a6186, #89253e);
            color: $color-white;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            border-radius: 50%;
            border: 2px solid $color-primary;

            @include respond(tab-land) {
                width: 60%;
                height: 30rem;
            }

            @include respond(phone-large) {
                width: 70%;
                
            }

            @include respond(phone-medium) {
                width: 80%;
               
            }

            @include respond(phone-large) {
                width: 90%;
            }

        }

        &__prize {

            font-size: 5rem;
            font-weight: bold;

        }

        &__title {
            font-size: 4rem;
        }

    }

    &__details {

        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__box {

        }

        &__item {
            margin-bottom: 2rem;
            h4 {
                margin-bottom: 1rem;
            }

            p {

            }
        }

    }
}